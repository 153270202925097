import { apiClient } from 'agent/apiClient';
import { ISitesService } from './interface';

const SitesService: ISitesService = {
	getSitesPaginated: async (data) => {
		let queryParamsString = '';
		type ObjectKey = keyof typeof data.queryParams;
		Object.keys(data.queryParams).forEach((key) => {
			const myKey = `${key}` as ObjectKey;
			if (data.queryParams && data.queryParams[myKey]) {
				queryParamsString += `${myKey}=${data.queryParams[myKey]}&`;
			}
		});

		return await apiClient.get(`sites/?${queryParamsString}`).then((response) => response.data);
	},

	getAllSites: async () => {
		return await apiClient.get(`/sites/`).then((response) => response.data);
	},

	getSite: async (data) => {
		let queryParamsString = '';
		if (data.queryParams) {
			type ObjectKey = keyof typeof data.queryParams;
			Object.keys(data.queryParams).forEach((key) => {
				const myKey = `${key}` as ObjectKey;
				if (data.queryParams && data.queryParams[myKey]) {
					queryParamsString += `${myKey}=${data.queryParams[myKey]}&`;
				}
			});
		}

		return await apiClient
			.get(`/sites/${data.site_id}/?${queryParamsString}`)
			.then((response) => response.data);
	},
	createSite: async (data) => {
		return await apiClient.post('/sites/', data).then((res) => res.data);
	},

	updateSite: async (data) => {
		return await apiClient.put(`/sites/${data.site_id}/`, data);
	},

	getAssociations: async (site_id) => {
		return await apiClient.get(`/sites/${site_id}/associations/`).then((res) => res.data);
	},
	createAssociation: async ({ site_id, organization_id }) => {
		return await apiClient.post(`/sites/${site_id}/associations/`, {
			organization_id: organization_id
		});
	},
	removeAssociation: async ({ site_id, association_id }) => {
		return await apiClient.delete(`/sites/${site_id}/associations/${association_id}/`);
	},
	getSiteImage: async (image, site_id) => {
		return await apiClient.get(`/sites/${site_id}/image/`);
	},
	updateSiteImage: async (image, site_id) => {
		const formData = new FormData();
		formData.append('image', image ?? '');
		return await apiClient.put(`/sites/${site_id}/image/`, formData, {
			headers: {
				'content-type': 'multipart/form-data'
			}
		});
	},
	deleteSiteImage: async (site_id) => {
		return await apiClient.delete(`/sites/${site_id}/image/`);
	}
};

export default SitesService;
