import { Cancel } from '@mui/icons-material';
import ModalContainer from 'components/Modal/ModalContainer';
import Dialog from 'components/shared/ConfirmationDialog/Dialog';
import NavigationBlocker from 'components/shared/NavigationBlocker';
import { useGlobalStore } from 'global-state/useStore';
import { useEffect, useMemo } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Outlet } from 'react-router-dom';
import AuthService from 'services/AuthService/AuthService';
import 'styles/app.scss';

const App = () => {
	const confirmationDialog = useGlobalStore((state) => state.confirmationDialog);
	const setHostname = useGlobalStore((state) => state.setHostname);

	useEffect(() => {
		AuthService.checkToken();
		setHostname(window.location.hostname);
	}, []);

	const memorizedConfirmationDialog = useMemo(() => <Dialog />, [confirmationDialog]);

	return (
		<>
			<NavigationBlocker />
			<Toaster
				position="top-center"
				containerClassName="text-center"
				toastOptions={{
					className: 'bg-primary border border-gray-500 rounded-md',
					duration: 4000,
					style: { paddingRight: '16px', paddingLeft: '16px' },
					success: { duration: 3000 },
					error: {
						duration: 5000,
						icon: <Cancel onClick={() => toast.dismiss()} className="cursor-pointer text-danger" />
					}
				}}
			/>
			<ModalContainer />
			<Outlet />
			{memorizedConfirmationDialog}
		</>
	);
};

export default App;
